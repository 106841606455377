import * as Sentry from "@sentry/react";
import { getConfig } from "./config";

const INITIAL_RECONNECT_TIME = 1000;
const MAX_RECONNECT_TIME = 10000;
let reconnectTime = INITIAL_RECONNECT_TIME;

// const WS_URL = "ws://35.203.109.132";
export function connect() {
  const { wsUrl, apiKey } = getConfig();

  const ws = new WebSocket(`${wsUrl}/ws/locker/${apiKey}`);

  ws.onopen = function (e) {
    console.log("ws open", e);
    reconnectTime = INITIAL_RECONNECT_TIME;
  };

  ws.onmessage = async function (e) {
    const message = JSON.parse(e.data);
    if (message.event && message.event.type === "door.open") {
      const position = message.event.door;
      const x = position[0];
      const y = position.substring(1);
      await fetch(`http://127.0.0.1:5000/?xpos=${x}&ypos=${y}`);
    }
  };

  ws.onerror = function (e) {
    console.error("ws error", e);
    Sentry.captureException(e);
    ws.close();
  };

  ws.onclose = function (e) {
    console.error(`ws closed. Reconnection in ${reconnectTime}ms`, e);
    setTimeout(function () {
      reconnectTime = Math.min(reconnectTime * 1.1, MAX_RECONNECT_TIME);
      connect();
    }, reconnectTime);
  };

  window.onbeforeunload = function () {
    if (ws && ws.readyState === ws.OPEN) {
      ws.close();
    }
  };
}
